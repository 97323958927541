var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "app-dialog",
      attrs: {
        visible: _vm.modulxModal,
        "before-close": _vm.closeModal,
        "close-on-click-modal": false,
        width: "400px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.modulxModal = $event
        },
      },
    },
    [
      _c(
        "template",
        { slot: "title" },
        [_c("title-icon"), _vm._v(_vm._s(_vm.$t("module.open")) + "\n    ")],
        1
      ),
      _vm.modulx.riseModule == "Y"
        ? [
            _c("div", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("module.openTips4")) +
                  "\n        "
              ),
            ]),
            _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("module.openTips5")) +
                  "\n        "
              ),
            ]),
          ]
        : [
            _c("span", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("module.openTips6")) +
                  "\n        "
              ),
            ]),
          ],
      _vm.modulx.riseModule == "Y"
        ? [
            _c(
              "el-form",
              {
                ref: "modulxForm",
                attrs: {
                  model: _vm.modulx,
                  rules: _vm.formRule,
                  "label-width": "70px",
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("module.duration"),
                      prop: "unitPcs",
                    },
                  },
                  [
                    _c("el-input-number", {
                      staticStyle: { width: "112px", "margin-right": "2px" },
                      attrs: {
                        "controls-position": "right",
                        min: 1,
                        size: "mini",
                      },
                      on: { change: _vm.calTotalPrice },
                      model: {
                        value: _vm.modulx.unitPcs,
                        callback: function ($$v) {
                          _vm.$set(_vm.modulx, "unitPcs", $$v)
                        },
                        expression: "modulx.unitPcs",
                      },
                    }),
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.$t("module.duration" + _vm.modulx.priceUnit)
                        ) +
                        "\n            "
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: { label: _vm.$t("module.price"), prop: "unitPrice" },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "¥" +
                          _vm._s(_vm.modulx.price) +
                          "/" +
                          _vm._s(
                            _vm.$t("module.unitPrice" + _vm.modulx.priceUnit)
                          )
                      ),
                    ]),
                  ]
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("module.totalPrice") } },
                  [_c("span", [_vm._v("¥" + _vm._s(_vm.totalPrice))])]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.closeModal } }, [
            _vm._v(_vm._s(_vm.$t("commons.cancel"))),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v(_vm._s(_vm.$t("commons.submit")))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }