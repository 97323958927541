<template>
    <div class="app-container">
        <div v-loading="loading">
            <el-row>
                <template v-if="moduleList.length > 0">
                    <el-col class="ruge-cards" :span="5" v-for="(modulx, index) in moduleList" :key="modulx.moduleCode" :offset="index % 4==0 ? 0 : 1">
                        <el-card class="moduleCard">
                            <div class="moduleCardHeader" slot="header">
                                <title-icon/><span>{{modulx.moduleName}}</span>
                                <template v-if="modulx.moduleStatus">
                                    <el-tag :type="modulx.moduleStatus | statusTagFilter" style="float: right;">{{$t('module.moduleStatus' + modulx.moduleStatus)}}</el-tag>
                                </template>
                                <template v-else>
                                    <el-tag type="info" style="float: right;">{{$t('module.notOpen')}}</el-tag>
                                </template>
                            </div>
                            <div>
                                <template v-if="modulx.riseModule == 'Y'">
                                    <span>{{$t('module.riseModule')}}</span>
                                    <template v-if="modulx.moduleStatus == 2 || modulx.moduleStatus == 7" >
                                        <!--显示服务截止时间-->
                                        <template v-if="modulx.expireDate">
                                            <span style="float: right;">{{$t('module.expireDate')}}:{{modulx.expireDate | dateFormat('YYYY-MM-DD') }}</span>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <span style="float: right;">¥{{modulx.price}}/{{$t('module.unitPrice' + modulx.priceUnit)}}</span>
                                    </template>
                                </template>
                                <template v-else-if="modulx.riseModule == 'N'">
                                    <span>{{$t('module.freeModule')}}</span>
                                </template>
                                <template v-else>
                                    <span>{{$t('module.unPricing')}} </span>
                                </template>
                                <div class="bottom">
                                    <div>
                                        <template v-if="!modulx.moduleStatus || modulx.moduleStatus == 3 || modulx.moduleStatus==6">
                                            <el-button @click="onOpenModulx(modulx)" type="text">{{$t('module.open')}}</el-button>
                                        </template>
                                        <template v-if="modulx.moduleStatus == 2 || modulx.moduleStatus==7">
                                            <template v-if="modulx.riseModule == 'Y'">
                                                <el-button @click="onRenewalModulx(modulx)" type="text">{{$t('module.renewal')}}</el-button>
                                            </template>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </template>
            </el-row>

            <template v-if="openModuleModal">
                <open-modulx @cancelModal="cancelModalOpen" :modulx="currentOpenModulx" @onModalModulx="onModalModulx"></open-modulx>
            </template>
        </div>
    </div>
</template>

<style rel="stylesheet/scss" lang="scss" scoped>
    .ruge-cards {
        .el-card {
            cursor: auto;
        }
        .moduleCardHeader {
            line-height: 30px;
        }
    }

    .bottom {
        margin-top: 13px;
        line-height: 12px;
        text-align: right;
        height: 38px;
    }
</style>

<style scoped>
    .moduleCard >>> .el-card__header {
        padding: 10px 16px;
    }
</style>

<script>

let modulxApi = require('@/api/ruge/tenant/modulx')
import OpenModulx from '@/views/ruge/modulx/openModulx'

export default {
    components: {OpenModulx},
    data() {
        return {
            loading: true,
            moduleList: [],
            openModuleModal: false,
            currentOpenModulx: {},
        };
    },
    created() {
        this.tenantId = this.$store.getters.currentTenant;
        this.getAllModule()
    },
    methods: {
            getAllModule() {
                this.loading = true;
                modulxApi._tenant_findOpendModuleList({tenantId: this.tenantId}).then(rsp=>{
                    rsp.map(r=>{
                        r.moduleName = r.moduleName.length>15?r.moduleName.substring(0, 15)+"...":r.moduleName;
                    });
                    this.moduleList = rsp;
                }).finally(() => {
                    this.loading = false;
                })
            },
            onOpenModulx(modulx) {
                this.currentOpenModulx = modulx;
                this.openModuleModal = true;
            },
            onModalModulx() {
                this.currentOpenModulx = {};
                this.openModuleModal = false;
                this.getAllModule();
            },
            cancelModalOpen() {
                this.currentOpenModulx = {};
                this.openModuleModal = false;
            },
            onRenewalModulx(modulx) {
                if(modulx) {
                    this.$message({
                        type: 'info',
                        message: this.$t('module.message.unsupport')
                    });
                }
            }
    }
}
</script>
